.CreateProperty{
    padding-top: 28px;
    .UploadImg{
        .ant-upload-wrapper{
            .ant-upload-list{
                .ant-upload{
                    p{
                        margin-bottom: 0;
                    }
                    
                    .subtitle{
                        font-size: 12px;
                        color: rgba(0,0,0,0.45);
                    }
                }
            }
        }
        .CropUpload{
            .up-image{
                margin-top: 30px;
            }
            .ant-upload-wrapper{
                .ant-upload-list{
                    display: flex;
                    flex-wrap: wrap;
                    .ant-upload-list-item-container{
                        // box-sizing: border-box;
                        // border: 1px solid rgba($color: #000000, $alpha: .08);
                        // border-radius: 8px;
                        // box-shadow:  0 0 8px rgba($color: #000000, $alpha: .1);
                        // padding: 0 6px;
                        // display: flex;
                        // align-items: center;
                        margin-bottom: 30px;
                        >.ant-upload-list-item{
                            display: block;
                            position: relative;
                            .ant-upload-list-item-actions{

                            }
                            .upload-img{
                                display: flex;
                                align-items: center;
                                width: 100%;
                                height: 100%;
                            }
                            .surrounding-desc{
                                margin-top: 10px;
                                display: flex;
                                justify-content: flex-end;
                                p{
                                    margin-bottom: 0;
                                }
                                .desc-text{
                                    width: 70px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    .tip{
                                        color: rgba($color: #000000, $alpha: .45);
                                    }
                                }
                                .anticon{
                                    // margin-left: 5px;
                                    // position: absolute;
                                    // top: 4px;
                                    // right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .ant-radio-group{
        margin-bottom: 10px;
    }
    .title{
        font-size: 20px;
        line-height: 28px;
        padding-left: 16px;
    }
    .content{
        .steps{
            padding: 8px 30px 16px;
            border-bottom: 1px solid rgba($color: #000000, $alpha: .06);
            margin-bottom: 24px;
            .ant-steps{
                width: 700px;

            }
        }
        .create-content{
            padding-bottom: 100px;
            .create-item{
                margin-bottom: 24px;
                max-width: 80%;
                .create-title{
                    font-size: 14px;
                    line-height: 22px;
                    &::before{
                        content: '*';
                        color: red;
                    }
                }
            }
        }
        .create-content2{
            padding-bottom: 100px;
            h3{
                font-size: 14px;
                font-weight: normal;
                color: rgba(0,0,0,0.85);
                line-height: 22px;
            }
            .ant-input{
                width: 1200px;
            }
            .BasicData{
                margin-top: 24px;
                .property-item{
                    margin-bottom: 30px;
                }
                .property-material{
                    display: flex;
                    .property-amount{
                        margin-right: 108px;
                    }
                    .margin{
                        margin: 0 15px;
                    }
                    .unit{
                        margin-left: 10px;
                    }
                }
                .property-room{
                    .ant-input{
                        width: 130px;
                    }
                    .unit{
                        margin-left: 10px;
                    }
                }
                .tenant-data{
                    .w-e-text-container{
                        padding-bottom: 20px;
                    }
                }
            }
            .Map{
                margin-top: 30px;
                margin-bottom: 30px;
                .property-location{
                    margin-bottom: 30px;
                    .mapValue{
                        display: flex;
                        .open{
                            width: 100px;
                            height: 32px;
                            background-color: #000000;
                            border-radius: 2px;
                            color: white;
                            line-height: 32px;
                            text-align: center;
                            font-size: 14px;
                            margin-right: 10px;
                            cursor: pointer;
                        }
                        .ant-input{
                            border-radius: 0;
                        }
                    }
                    .mapTitle{
                        display: flex;
                        align-items: center;
                        margin-bottom: 8px;
                        h3{
                            line-height: 29px;
                            margin-bottom: 0;
                        }
                        .question{
                            width: 18px;
                            height: 18px;
                            text-align: center;
                            line-height: 18px;
                            background-color: #000000;
                            color: white;
                            border-radius: 50%;
                            margin-left: 5px;
                            cursor: pointer;
                        }
                    }
                }
            }
            .Surroundings{
                margin-top: 30px;
                h3{
                    margin-bottom: 16px;
                }
            }
            .w200{
                width: 200px;
            }
            .w450{
                width: 450px;
            }
            .m50{
                margin-right: 50px;
            }
            .Equipment{
                margin-top: 30px;
                .equipment-item{
                    display: flex;
                    margin-bottom: 30px;
                }
            }
            .Detailed{
                margin-top: 30px;
                .detail-item{
                    display: flex;
                    margin-bottom: 30px;
                    .advantage-item{
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }
            .EquipmentAndDetailed{
                .icon-list{
                    display: flex;
                    flex-wrap: wrap;
                    width: 854px;
                    margin-top: 46px;
                    .list-item{
                        text-align: center;
                        margin-right: 22px;
                        margin-bottom: 19px;
                        cursor: pointer;
                        .icon{
                            width: 67px;
                            height: 67px;
                            margin-bottom: 7px;
                        }
                        .text{
                            width: 100px;
                            font-size: 13px;
                            color: #999999;
                        }
                    }
                    .list-item-active{
                        .text{
                            color: #1890FF;
                        }
                    }
                }
                .inp-list{
                    margin-top: 44px;
                    width: 854px;
                    .inp-item{
                        margin-bottom: 31px;
                        .title{
                            font-size: 16px;
                            line-height: 24px;
                            margin-bottom: 9px;
                            font-weight: 400;
                            color: #000000;
                            padding-left: 0;
                        }
                        .ant-input{
                            border-radius: 0;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

}

.PropertyList{
    .weights{
        width: 48px;
        height: 34px;
        text-align: center;
    }
    .list-title{
        height: 48px;
        display: flex;justify-content: space-between;
        padding-left: 24px;
        .ant-breadcrumb{
            line-height: 48px;
            font-size: 20px;
        }
        // .ant-select{
        //     .ant-select-selector{
        //         height: 48px;
        //         .ant-select-selection-item{
        //             line-height: 48px;
        //             font-size: 16px;
        //             color: #1890FF;
        //             border-bottom: 2px solid #1890FF;
        //             padding-right: 0;
        //             margin-right: 20px;
        //         }
        //     }
        // }
        .title-right{
            line-height: 48px;
            display: flex;
            align-items: center;
            margin-right: 100px;
            .search-icon{
                width: 32px;
                height: 32px;
                border: 1px solid rgba(0,0,0,0.15);
                line-height: 32px;
                text-align: center;
            }
            .ant-input{
                height: 32px;
                border-radius: 0;
            }
            .ant-btn{
                margin-left: 16px;
            }
        }
    }
    .DisplayList{
        padding-left: 24px;
        .list-content{
            margin-top: 16px;
            .ant-table-content{
                .ant-table-thead{
                    .ant-table-cell{
                        &:nth-of-type(6){
                            width: 200px;
                            overflow: visible;
                        }
                        .companyTable{
                            width: 160px;
                            .ant-select-dropdown{
                                width: 200px !important;
                            }
                        }
                    }
                }
                .ant-table-tbody{
                    .ant-table-cell{
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        max-width: 200px;
                        img{
                            width: 100px;
                            object-fit: cover;
                            height: 60px;
                        }
                        .name,img{
                            cursor: pointer;
                        }
                        .name{
                            &:hover{
                                color: #1890FF;
                            }
                        }
                    }
                }
            }
        }
    }
}

.propertyModal{
    width: 1000px !important;
    .CreateProperty{
        .content{
            .create-content2{
                .ant-input{
                    width: 100%;
                }
                .BasicData{
                    .tenant-data{
                        >div{
                            width: 100% !important;

                        }
                    }
                }
            } 
        } 
        .create-footer{
            .steps-btn{
                .ant-btn{
                    margin-right: 10px;
                }
            }
        }
    }
}

.cropperModal{
    width: 600px !important;
    .ant-modal-content{
        text-align: center;
        .cropper-container{
            // height: auto !important;
            // min-height: 400px;
            // .cropper-canvas{
                
            //     transform: translateY(0) !important;
            // }
        }
        .ant-btn{
            margin-top: 30px;
            width: 200px;
            height: 50px;
        }
        .ant-modal-close{
            display: none;
        }
    }
}

.loading-modal{
    .ant-modal-content{
        height: 520px;
        .ant-modal-close{
            display: none;
        }
        .ant-modal-body{
            text-align: center;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .ant-spin{
            .ant-spin-dot{
                width: 40px;
                height: 40px;
                .ant-spin-dot-item{
                    width: 20px;
                    height: 20px;
                }
            }
        }
        .loading-text{
            font-size: 20px;
        }
    }
}

.questModal{
    width: 1060px !important;
    .google{
        width: 100%;
        padding-top: 50px;
        img{
            width: 100%;
        }
    }
}

.descModal{
    .desc-content{
        text-align: center;
        .ant-input-textarea{
            margin-bottom: 30px;
        }
    }
}

