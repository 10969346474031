.app {
    font-size: 1.6rem;
    @media screen and (max-width: 960px) {
      font-size: 1.4rem;
    }
    .aside {
      position: fixed;
      left: 0;
      height: 100vh;
      overflow-y: auto;
      
    }
    .weightsInp{
        width: 48px;
        height: 34px;
        background-color: #979797;
        color: #000000;
        text-align: center;
        border-radius: 0;
    }
    .logo {
      height: 3rem;
      margin: 1rem;
      box-sizing: border-box;
      border-radius: 1rem;
      padding: 0 1rem;
      text-align: center;
      img {
        height: 100%;
      }
    }
  
    .header {
      background-color: #fff;
      padding: 0 1.5rem;
      display: flex;
      justify-content: space-between;
      .right {
        display: flex;
        align-items: center;
      }
    }
  
    .content {
      padding: 10px 18px;
      width: auto !important;
    }
  
    .footer {
      text-align: center;
    }

    .site-layout{
      .ant-layout-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .header-logo{
          width: 178px;
          height: 33px;
          background-color: aqua;
          margin-left: 23px;
          font-size: 0;
          img{
            width: 100%;
            height: 100%;
            margin-top: -30px;
          }
        }
        .header-user{
          cursor: pointer;
          .ant-dropdown-trigger{
            height: 48px;
          }
          >div{
            margin-right: 64px;
            display: flex;
            align-items: center;
          }
            .user-avator{
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background-color: bisque;
                margin-right: 8px;
                img{
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                }
            }
            .user-name{
                color: white;
            }
            .ant-dropdown{
              width: 220px;
              .ant-dropdown-menu{
                border-radius: 0;
                text-align: left;
                padding: 0;
                padding-bottom: 10px;
                .ant-dropdown-menu-item{
                  padding: 9px 0 9px 19px;
                  border-bottom: 1px solid rgba($color: #000000, $alpha: .08);
                  border-radius: 0;
                  &:last-child{
                    border: none;
                  }
                  .userTitle{
                    color: #989898;
                  }
                  .dropBox{
                    display: flex;
                    align-items: center;
                    a{
                      color: #000000;
                    }
                    img{
                      width: 16px;
                      height: 16px;
                      margin-right: 6px;
                    }
                  }
                }
              }
            }
        }
      }
    }
  }
  