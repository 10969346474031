.RecordList{
    .list-title{
        padding-left: 13px;
        .ant-select{
            .ant-select-selector{
                height: 48px;
                .ant-select-selection-item{
                    line-height: 48px;
                    font-size: 16px;
                    color: #1890FF;
                    border-bottom: 2px solid #1890FF;
                    padding-right: 0;
                    margin-right: 20px;
                }
            }
        }
    }
    .DisplayList{
        padding-left: 24px;
        margin-top: 16px;
        .ant-table-content{
            .ant-table-tbody{
                .ant-table-cell{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 200px;
                    img{
                        width: 100px;
                        object-fit: cover;
                        height: 60px;
                    }
                    &:nth-of-type(10){
                        min-width: 100px;
                    }
                }
            }
        }
    }
    // .recordTable{
    //     .ant-table{
    //         .ant-table-content{
    //             .ant-table-thead{
    //                 tr{
    //                     .ant-table-cell{
    //                         &:nth-of-type(3){
    //                             width: 100px;
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }
}

.ant-modal{
    width: 500px;
    .ant-modal-content{
        .recordModal{
            margin-top: 40px;
            .title{
                text-align: center;
                font-size: 16px;
                padding-bottom: 15px;
                border-bottom: 1px solid rgba($color: #000000, $alpha: .09);
            }
            .content{
                height: 179px;
                .ant-input{
                    min-height: 179px;
                }
            }
            .btn{
                text-align: center;
                margin: 60px 0;
            }
        }
    }
}

.ant-tooltip{
    .ant-tooltip-content{
        .ant-tooltip-inner{
            padding: 20px;
            color: rgba($color: #000000, $alpha: .65);
        }
    }
}

