.CreateBanner{
    .title{
        font-size: 16px;
        line-height: 56px;
        height: 56px;
        padding-left: 24px;
        border-bottom: 1px solid rgba($color: #000000, $alpha: .06);
    }
    .content{
        padding-left: 40px;
        .create-item{
            margin-bottom: 25px;
        }
    }
    .upload-img{
        .upload-box{
            display: flex;
            .img-box{
                width: 178px;
                height: 104px;
                border: 1px solid #D4D4D4;
                font-size: 22px;
                line-height: 104px;
                text-align: center;
                color: rgba($color: #000000, $alpha: .4);
            }
        }
    }
    .UploadImg{
        .ant-upload-wrapper{
            .ant-upload-list{
                .ant-upload{
                    p{
                        margin-bottom: 0;
                    }
                    
                    .subtitle{
                        font-size: 12px;
                        color: rgba(0,0,0,0.45);
                    }
                }
            }
        }
    }
    .create-item:nth-child(3){
        .ant-input{
            opacity:0.75;
        }
    }
}


.BannerList{
    .title-box{
        height: 48px;
        line-height: 48px;
        padding-left: 24px;
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        .title{
            color: #1890FF;
            display: inline-block;
            border-bottom: 2px solid #1890FF;
        }
        .title-right{
            line-height: 48px;
            display: flex;
            align-items: center;
            margin-right: 100px;
            .search-icon{
                width: 32px;
                height: 32px;
                border: 1px solid rgba(0,0,0,0.15);
                line-height: 32px;
                text-align: center;
            }
            .ant-input{
                height: 32px;
                border-radius: 0;
            }
            .ant-btn{
                margin-left: 16px;
            }
        }
    }
    .content{
        .ant-table-wrapper{
            .ant-spin-container{
                .ant-pagination{
                    position: fixed;
                    right: 24px;
                    bottom: 26px;
                }
            }
            .ant-table-cell{
                input{
                    width: 43px;
                    height: 31px;
                    background: #D8D8D8;
                    border: 1px solid #979797;
                    text-align: center;
                }
            }
        }
    }
}



