
.login{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    h3{
        font-size: 20px;
        text-align: left;
        margin: 0;
    }
    .model{
        width: 350px;
        padding: 20px;
        background-color: white;
        border-radius: 5px;
        box-shadow: 0 2px 13px 0 hsl(0deg 0% 89% / 60%);
    }
    .login-form-button{
        width: 100%;
    }
}