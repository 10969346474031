.site-layout{
    .ant-layout-content{
        text-align: left;
        min-height: calc(100vh - 125px);
        background-color: white;
        border: 1px solid rgba($color: #000000, $alpha: 0.08);
        box-shadow: 0px 6px 16px 0px rgba(0,0,0,0.08), 0px 9px 4px 0px rgba(0,0,0,0.05), 0px 12px 48px 16px rgba(0,0,0,0.03);
        border-radius: 2px;
        position: relative;
        z-index: 1;
        .create-footer{
            position: fixed;
            bottom: 0;
            height: 52px;
            width: calc(100% - 232px);
            border: 1px solid rgba($color: #000000, $alpha: .06);
            border-inline: none;
            background-color: white;
            text-align: right;
            line-height: 52px;
            padding-right: 24px;
            .steps-btn{
                display: inline-block;
            }
            .ant-btn{
                border-radius: 0;
            }
            .ant-btn-primary{
                margin-left: 8px;
            }
        }
    }
}

.ant-layout{
    .ant-layout-sider{

    }
}
