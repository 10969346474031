.CreateCompany{
    .UploadImg{
        .ant-upload-wrapper{
            .ant-upload-list{
                .ant-upload{
                    p{
                        margin-bottom: 0;
                    }
                    
                    .subtitle{
                        font-size: 12px;
                        color: rgba(0,0,0,0.45);
                    }
                }
            }
        }
    }
    .create-title{
        font-size: 16px;
        line-height: 56px;
        height: 56px;
        padding-left: 24px;
        border-bottom: 1px solid rgba($color: #000000, $alpha: .06);
        h1{
            font-weight: normal;

        }
    }
    .create-content{
        margin-top: 30px;
        padding-left: 40px;
        .content-item{
            margin-bottom: 30px;
            h3{
                font-size: 14px;
                line-height: 22px;
                font-weight: normal;

            }
            .w980{
                width: 980px;
            }
        }
        .fee{
            .fee-item{
                display: flex;
                align-items: center;
                .special{
                    margin-left: 10px;
                }
            }
        }
    }
}

.CompanyList{
    .list-title{
        height: 48px;
        line-height: 48px;
        padding-left: 24px;
        margin-bottom: 6px;
        h1{
            color: #1890FF;
            display: inline-block;
            border-bottom: 2px solid #1890FF;
            font-size: 16px;
        }
    }
    .list-content{
        .DisplayList{
            margin-top: 16px;
            padding-left: 24px;
            .ant-table-content{
                .ant-table-tbody{
                    .ant-table-cell{
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        max-width: 200px;
                        img{
                            width: 100px;
                            object-fit: cover;
                            height: 60px;
                        }
                    }
                }
            }
        }
    }
}

.CompanyModal{
    width: 800px !important;
    .ant-modal-content{
        .ant-modal-body{
            .CreateCompany{
                .create-content{
                    .content-item{
                        .ant-input{
                            width: 100%;
                        }
                    }
                }
                .create-footer{
                    text-align: center;
                    .ant-btn{
                        &:nth-child(1){
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

