.UserManagement{
    .management-title{
        height: 64px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title{
            h3{
                line-height: 62px;
                margin-bottom: 0;
                color: #1890FF;
                border-bottom: 2px solid #1890FF;
                box-sizing: border-box;
            }
            margin-left: 26px;
        }
        .ant-btn{
            margin-right: 16px;
        }
    }
    .management-content{
        .ant-table-wrapper{
            padding: 0 16px 0 24px;
        }
    }
}

.create-modal{
    width: 842px !important;
    .CreateUserModal{
        width: 376px;
        margin: 0 auto;
        h3{
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            color: rgba($color: #000000, $alpha: .85);
        }
        .create-title{
            height: 56px;
            line-height: 56px;
            margin-bottom: 24px;
            border-bottom: 1px solid rgba($color: #000000, $alpha: .09);
            h2{
                font-size: 16px;
                margin-left: 24px;
            }
        }
        .create-content{
            padding: 0 24px;
            .content-item{
                margin-bottom: 30px;
                .permission-check{
                    height: 245px;
                    width: 328px;
                    box-shadow: 0px 0px 4px rgba($color: #000000, $alpha: .08);
                    .check-all{
                        height: 40px;
                        line-height: 40px;
                        border-bottom: 1px solid rgba($color: #000000, $alpha: .08);
                        margin-bottom: 10px;
                        .ant-checkbox-wrapper{
                            margin-left: 20px;
                        }
                    }
                    .ant-checkbox-group{
                        .ant-col{
                            height: 32px;
                            line-height: 32px;
                            padding-left: 20px;
                        }
                    }
                }
            }
        }
    }
}



