.Personal{
    width: 376px;
    margin: 36px auto 0;
    .UploadImg{
        .ant-upload-wrapper{
            .ant-upload-list{
                .ant-upload{
                    p{
                        margin-bottom: 0;
                    }
                    
                    .subtitle{
                        font-size: 12px;
                        color: rgba(0,0,0,0.45);
                    }
                }
            }
        }
    }
    .personal-title{
        height: 56px;
        border-bottom: 1px solid rgba(0,0,0,0.09);
        h3{
            line-height: 56px;
            padding-left: 24px;
        }
    }
    .personal-content{
        padding: 0 24px;
        margin-top: 30px;
        h3{
            font-size: 14px;
            font-weight: normal;
            line-height: 22px;
            margin-bottom: 8px;
            color: rgba($color: #000000, $alpha: .85);
        }
        .item{
            margin-bottom: 30px;
            .pwd{
                display: flex;
                justify-content: space-between;
                align-items: center;
                span{
                    color: rgba($color: #1890FF, $alpha: .85);
                }
            }
        }
    }
    .ant-btn{
        margin-left: 24px;
    }
}


.passwdModal{
    .ant-modal-content{
        .ant-modal-body{

            >p{
                margin-top: 30px;
                span{
                    color: red;
                }
            }
            .btn{
                text-align: center;
                margin-top: 40px;
            }
        }
    }
}


