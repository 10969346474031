.CreateRoom{
    padding-top: 28px;
    .create-title{
        font-size: 20px;
        line-height: 28px;
        padding-left: 24px;
        padding-bottom: 16px;
        border-bottom: 1px solid rgba($color: #000000, $alpha: .06);
        margin-bottom: 16px;
    }
    .create-content{
        padding-left: 24px;
        display: flex;
        .content-left{
            padding-right: 43px;
            margin-right: 33px;
            border-right: 1px solid rgba($color: #000000, $alpha: .06);
            .room-item{
                display: flex;
                margin-bottom: 30px;
                .m165{
                    margin-right: 165px;
                }
                h3{
                    font-size: 14px;
                    color: #262626;
                    font-weight: normal;
                    margin-bottom: 16px;
                }
                .floor,.room,.area,.introduction,.desc,.rental{
                    h3{
                        &::before{
                            content: "*";
                            color: #F5222D;
                        }
                    }
                }
            }
        }
        .content-right{
            .property-img{
                width: 400px;
                height: 240px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .property-name{
                margin: 24px 0;
                font-size: 16px;
                line-height: 24px;
                font-weight: normal;
                color: rgba($color: #000000, $alpha: .85);
            }
            .property-desc{
                font-size: 14px;
                line-height: 22px;
                color: rgba($color: #000000, $alpha: .45);
                &::before{
                    content: '•';
                    margin-right: 9px;
                }
            }
        }
    }
    .UploadImg{
        .upload-img{
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
        }
        .ant-upload-wrapper{
            .ant-upload-list{
                .ant-upload{
                    p{
                        margin-bottom: 0;
                    }
                    
                    .subtitle{
                        font-size: 12px;
                        color: rgba(0,0,0,0.45);
                    }
                }
            }
        }
    }
}


.RoomList{
    padding-left: 24px;
    .list-title{
        // height: 48px;
        // display: flex;justify-content: space-between;
        .ant-breadcrumb{
            line-height: 48px;
            height: 48px;
            font-size: 20px;
            border-bottom: 1px solid #E0E0E0;
            margin-bottom: 18px;
            .ant-breadcrumb-link{
                display: inline-block;
                color: #1890FF;
                a{
                    display: inline-block;
                    height: 48px;
                    color: #252525;
                }
            }
        }
        .title-right{
            display: flex;
            justify-content: end;
            padding-right: 24px;
            .search-icon{
                width: 32px;
                height: 32px;
                border: 1px solid rgba($color: #000000, $alpha: .15);
                text-align: center;
                line-height: 32px;
                margin-right: 16px;
            }
        }
        .ant-select{
            .ant-select-selector{
                height: 48px;
                .ant-select-selection-item{
                    line-height: 48px;
                    font-size: 16px;
                    color: #1890FF;
                    border-bottom: 2px solid #1890FF;
                    padding-right: 0;
                    margin-right: 20px;
                }
            }
        }
    }
    .list-content{
        margin-top: 16px;
        .weights{
            width: 48px;
            height: 34px;
            text-align: center;
        }
        .ant-table-content{
            .ant-table-tbody{
                .ant-table-cell{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 200px;
                    img{
                        width: 100px;
                        height: 60px;
                        object-fit: cover;
                    }
                    &:nth-of-type(5){
                        width: 150px;
                    }
                }
            }
        }
    }
}

.roomModal{
    width: 1200px !important;
}


